<template>
    <div class="row row-container">
        <div class="col-12 row-container">
            <div class="row">
                <div class="col-12">
                    <OPersonsLookup :data-object="dsPersonsLookup" 
                        :bind="sel => addEntry(sel, 'Persons')" 
                        noClear
                        multiselect
                        :whereClause="personLkpWhereClause">
                        <template #target="{ target }">
                            <h6 :ref="target" class="d-inline text-primary" style="cursor:pointer" :title="$t('Grants immediate access to the document')"><i class="bi bi-plus-lg"></i> {{$t('Distribute / Grant access to Person')}}</h6>
                        </template>
                        <template #toolbarActions>
                            <OToolbarAction :text="$t('Restrict to users with access')" v-model="restrictToUsersWithAccess" />
                        </template>
                    </OPersonsLookup>

                    <ORolesLookup :bind="sel => addEntry(sel, 'Roles')" 
                        noClear
                        multiselect>
                        <template #target="{ target }">
                            <h6 :ref="target" class="ms-3 d-inline text-primary" style="cursor:pointer" :title="`${$t('Assign actions per role in document processes')} (${$t('Not access granting')})`"><i class="bi bi-plus-lg"></i> {{$t('Distribute to Role')}}</h6>
                        </template>
                    </ORolesLookup>

                    <div class="float-end">
                        <div class="form-check-inline">
                            <input class="form-check-input" type="radio" name="personsAndRolesRadioBtns" id="aggr" :checked="viewMode == 'all'" @change="viewMode = 'all'" 
                                    :disabled="props.dataObject.current.Confidential">
                            <label class="form-check-label ms-1" for="aggr" :title="`${$t('Persons and roles from any source')} (${$t('defined on document or added from distribution group')})`">
                                {{ $t("All") }}
                            </label>
                        </div>
                        <div class="form-check-inline ms-1">
                            <input class="form-check-input" type="radio" name="personsAndRolesRadioBtns" id="personsDoc" :checked="viewMode == 'docpersons'" @change="viewMode = 'docpersons'">
                            <label class="form-check-label ms-1" for="personsDoc" :title="$t('Persons defined on document (excluding persons added from Distr. groups)')">
                                {{ $t("Persons (excl. Distr. groups)") }}
                            </label>
                        </div>
                        <div class="form-check-inline ms-1">
                            <input class="form-check-input" type="radio" name="personsAndRolesRadioBtns" id="rolesDoc" :checked="viewMode == 'docroles'" @change="viewMode = 'docroles'">
                            <label class="form-check-label ms-1" for="rolesDoc" :title="$t('Roles defined on document (excluding roles added from Distr. groups)')">
                                {{ $t("Roles (excl. Distr. groups)") }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <ODataGrid :data-object="dsDocPersonsAll" hide-grid-menu v-if="viewMode == 'all'" style="max-height:400px;" 
                :recordInfoViewOverride="dsDocPersonsAll.current.Type == 'Person' ? 'aviw_Arena_DocumentsPersonsRecordInfo' : 'aviw_Arena_DocumentsRolesAgg'">
                <OColumn field="EntryName" width="200" sortable :headerName="$t('Person / Role')">
                    <template #default="{row}">
                        <span v-if="row.Type == 'Role'"><b>{{`${$t('Role')}:`}}</b>{{` ${row.EntryName}`}}</span>
                        <span v-else>
                            <span v-person-hover="row.Entry_ID" :class="{ 'text-danger': !row.HasPrimaryAccess }">{{row.EntryName}}</span>
                            <span class="float-end" @click="distributeToPerson(row.Entry_ID,row.EntryName)" style="cursor:pointer;" :title="$t('Inform Person')"><i class="bi bi-bell"></i></span>
                        </span>
                    </template>
                </OColumn>
                <OColumn field="RepresentsCompany" width="200" hide :headerName="$t('Represents Company')"></OColumn>
                <OColumn field="DistributionSource" width="200" :headerName="$t('Distribution Source')" :headerTitle="$t('Shows from which distribution group(s) the entry has been added from (if any)')"></OColumn>
                <OColumn field="ForReview" width="120" :editable="row => row.ForReviewEditable" :cellTitle="row => !row.ForReviewEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('For Review')" :headerTitle="$t('For user to be added as reviewer on initiate review')"></OColumn>
                <OColumn field="ForApproval" width="120" :editable="row => row.ForApprovalEditable" :cellTitle="row => !row.ForApprovalEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('For Approval')" :headerTitle="$t('For user to be added as approver on initiate approval')"></OColumn>
                <OColumn field="Check" width="80" :editable="row => row.CheckEditable" :cellTitle="row => !row.CheckEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Check')" :headerTitle="$t('For user to be added as checker on initiate check or approval')"></OColumn>
                <OColumn field="Sequence" width="80" hide :editable="row => row.SequenceEditable" :cellTitle="row => !row.SequenceEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('Sequence')" :headerTitle="$t('Specifies order of completion for Check / Approval actions')"></OColumn>
                <OColumn field="ForInfo" width="80" :editable="row => row.ForInfoEditable" :cellTitle="row => !row.ForInfoEditable ? $t('Cannot override settings from distribution group') : ''" :headerName="$t('For Info')" :headerTitle="$t('For user to receive For Info notifications')"></OColumn>
                <OColumn field="AllowEdit" width="100" :editable="row => row.AllowEditEditable" :cellTitle="row => row.Type == 'Role' ? $t('N/A') : (!row.AllowEditEditable ? $t('Cannot override settings from distribution group') : '')" :headerName="$t('Allow Edit')" :headerTitle="$t('Allow edit (if user does not already have edit permissions on document)')"></OColumn>
                <OColumn field="Subscribe" width="100" :editable="row => row.SubscribeEditable" :cellTitle="row => row.Type == 'Role' ? $t('N/A') : (!row.SubscribeEditable ? $t('Cannot override settings from distribution group') : '')" :headerName="$t('Subscribe')" :headerTitle="$t('Will add the document to home page under Documents Subscriptions')"></OColumn>
                <OColumn field="Redliner" width="100" hide :editable="row => row.RedlinerEditable" :cellTitle="row => row.Type == 'Role' ? $t('N/A') : (!row.RedlinerEditable ? $t('Cannot override settings from distribution group') : '')" :headerName="$t('Redliner')" :headerTitle="$t('User will be added as a Redline Participant when Redlining Process is initiated')"></OColumn>
                <OColumn field="RedlineVerifier" width="120" hide :editable="row => row.RedlineVerifierEditable" :cellTitle="row => row.Type == 'Role' ? $t('N/A') : (!row.RedlineVerifierEditable ? $t('Cannot override settings from distribution group') : '')" :headerName="$t('Redline Verifier')" :headerTitle="$t('User will be added as a Redline Verifier when Redlining Process is initiated')"></OColumn>
                <OColumn field="RedlineApprover" width="130" hide :editable="row => row.RedlineApproverEditable" :cellTitle="row => row.Type == 'Role' ? $t('N/A') : (!row.RedlineApproverEditable ? $t('Cannot override settings from distribution group') : '')" :headerName="$t('Redline Approver')" :headerTitle="$t('User will be added as a Redline Approver when Redlining Process is initiated')"></OColumn>
                <OColumn field="Checklist" width="200" hide :editable="row => row.Type == 'Person'" :headerName="$t('Checklist')" :headerTitle="$t('Assign a review checklist to this participant')" :cellTitle="row => row.Type == 'Role' ? $t('N/A') : ''">
                    <template #editor="{modelValue: row}">
                        <OChecklistsLookup 
                            :OrgUnitId="props.dataObject.current.OrgUnit_ID"
                            :bind="sel => { row.Checklist_ID = sel.ID, row.Checklist = sel.Name }"
                            textInput :textInputValue="row.Checklist">
                            <OColumn field="ID" width="100"></OColumn>
                            <OColumn field="Name" width="150"></OColumn>
                            <OColumn field="OrgUnit" width="250"></OColumn>
                            <OColumn field="Title" width="350"></OColumn>
                        </OChecklistsLookup>
                    </template>
                </OColumn>
                <OColumn field="Purpose" width="200" hide :editable="row => row.DocEntryPrimKey && row.PurposeEditable" :cellTitle="row => !row.DocEntryPrimKey || !row.PurposeEditable ? $t('Cannot override purpose from distribution group') : ''" :headerName="$t('Purpose')">

                </OColumn>
                <OColumn field="OrgUnit" width="150" hide :headerName="$t('Org Unit')"></OColumn>
                <OColumn field="Type" width="100" hide :headerName="$t('Type')"></OColumn>
            </ODataGrid>

            <ODataGrid :data-object="dsDocPersonsDocOnly" hide-grid-menu v-else-if="viewMode == 'docpersons'" style="max-height:400px;">
                <OColumn field="Person" width="200" sortable :headerName="$t('Person')">
                    <template #default="{row}">
                         <span v-person-hover="row.Person_ID" :class="{ 'text-danger': !row.HasPrimaryAccess }">{{row.Person}}</span>
                         <span class="float-end" @click="distributeToPerson(row.Person_ID,row.Person)" style="cursor:pointer;" :title="$t('Inform Person')"><i class="bi bi-bell"></i></span>
                    </template>
                </OColumn>
                <OColumn field="RepresentsCompany" width="200" hide :headerName="$t('Represents Company')"></OColumn>
                <OColumn field="ForReview" width="120" editable bulkUpdate :headerName="$t('For Review')" :headerTitle="$t('For user to be added as reviewer on initiate review')"></OColumn>
                <OColumn field="ForApproval" width="120" editable bulkUpdate :headerName="$t('For Approval')" :headerTitle="$t('For user to be added as approver on initiate approval')"></OColumn>
                <OColumn field="Check" width="80" editable bulkUpdate :headerName="$t('Check')" :headerTitle="$t('For user to be added as checker on initiate check or approval')"></OColumn>
                <OColumn field="Sequence" width="80" hide editable bulkUpdate :headerName="$t('Sequence')" :headerTitle="$t('Specifies order of completion for Check / Approval actions')"></OColumn>
                <OColumn field="ForInfo" width="80" editable bulkUpdate :headerName="$t('For Info')" :headerTitle="$t('For user to receive For Info notifications')"></OColumn>
                <OColumn field="AllowEdit" width="100" editable bulkUpdate :headerName="$t('Allow Edit')" :headerTitle="$t('Allow edit (if user does not already have edit permissions on document)')"></OColumn>
                <OColumn field="Subscribe" width="100" editable bulkUpdate :headerName="$t('Subscribe')" :headerTitle="$t('Will add the document to home page under Documents Subscriptions')"></OColumn>
                <OColumn field="Redliner" width="100" editable bulkUpdate hide :headerName="$t('Redliner')" :headerTitle="$t('User will be added as a Redline Participant when Redlining Process is initiated')"></OColumn>
                <OColumn field="RedlineVerifier" width="120" editable bulkUpdate hide :headerName="$t('Redline Verifier')" :headerTitle="$t('User will be added as a Redline Verifier when Redlining Process is initiated')"></OColumn>
                <OColumn field="RedlineApprover" width="130" editable bulkUpdate hide :headerName="$t('Redline Approver')" :headerTitle="$t('User will be added as a Redline Approver when Redlining Process is initiated')"></OColumn>
                <OColumn field="Checklist" width="200" :headerName="$t('Checklist')" editable bulkUpdate hide :headerTitle="$t('Assign a review checklist to this participant')">
                    <template #editor="{modelValue: row}">
                        <OChecklistsLookup 
                            :OrgUnitId="props.dataObject.current.OrgUnit_ID"
                            :bind="sel => { row.Checklist_ID = sel.ID, row.Checklist = sel.Name }"
                            textInput :textInputValue="row.Checklist">
                            <OColumn field="ID" width="100"></OColumn>
                            <OColumn field="Name" width="150"></OColumn>
                            <OColumn field="OrgUnit" width="250"></OColumn>
                            <OColumn field="Title" width="350"></OColumn>
                        </OChecklistsLookup>
                    </template>
                </OColumn>
                <OColumn field="Purpose" width="200" hide editable bulkUpdate :headerName="$t('Purpose')"></OColumn>
                <OColumn field="OrgUnit" width="150" hide :headerName="$t('Org Unit')"></OColumn>
            </ODataGrid>

            <ODataGrid :data-object="dsDocRolesDocOnly" hide-grid-menu v-else style="max-height:400px;">
                <OColumn field="Role" width="200" sortable :headerName="$t('Role')"></OColumn>
                <OColumn field="ForReview" width="120" editable bulkUpdate :headerName="$t('For Review')" :headerTitle="$t('For user to be added as reviewer on initiate review')"></OColumn>
                <OColumn field="ForApproval" width="120" editable bulkUpdate :headerName="$t('For Approval')" :headerTitle="$t('For user to be added as approver on initiate approval')"></OColumn>
                <OColumn field="Check" width="80" editable bulkUpdate :headerName="$t('Check')" :headerTitle="$t('For user to be added as checker on initiate check or approval')"></OColumn>
                <OColumn field="Sequence" width="80" hide editable bulkUpdate :headerName="$t('Sequence')" :headerTitle="$t('Specifies order of completion for Check / Approval actions')"></OColumn>
                <OColumn field="ForInfo" width="80" editable bulkUpdate :headerName="$t('For Info')" :headerTitle="$t('For user to receive For Info notifications')"></OColumn>
                <!-- <OColumn field="Checklist" width="200" :headerName="$t('Checklist')" editable bulkUpdate hide :headerTitle="$t('Assign a review checklist to this participant')"> -->
                    <!-- <template #editor="{modelValue: row}"> -->
                        <!-- <OChecklistsLookup --> 
                            <!-- :OrgUnitId="props.dataObject.current.OrgUnit_ID" -->
                            <!-- :bind="sel => { row.Checklist_ID = sel.ID, row.Checklist = sel.Name }" -->
                            <!-- textInput :textInputValue="row.Checklist"> -->
                            <!-- <OColumn field="ID" width="100"></OColumn> -->
                            <!-- <OColumn field="Name" width="150"></OColumn> -->
                            <!-- <OColumn field="OrgUnit" width="250"></OColumn> -->
                            <!-- <OColumn field="Title" width="350"></OColumn> -->
                        <!-- </OChecklistsLookup> -->
                    <!-- </template> -->
                <!-- </OColumn> -->
            </ODataGrid>
        </div>
    </div>
</template>

<script setup>
    import { ref,watch,computed,onMounted } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject'
    import { getOrCreateProcedure, userSession } from 'o365-modules'
    import { OPersonsLookup, OChecklistsLookup, ORolesLookup } from 'o365-system-lookups';
    import { alert, confirm } from 'o365-vue-services';
    import { useDataObjectEventListener } from 'o365-vue-utils';
    import vPersonHover from 'system.libraries.vue.directive.personHover.ts';

    const props = defineProps({
        dataObject: Object
    })

    const viewMode = ref(props.dataObject.current.Confidential ? 'docpersons' : 'all')

    watch(() => props.dataObject.current.Confidential, () => {
        if (props.dataObject.current.Confidential && viewMode.value == 'all'){
            viewMode.value = 'docpersons'
        }

    });
    watch(viewMode, () => {
        refreshGrid();
    });

    //TODO: now these seems to refreshed on refreshRow (parent), no need to
    const dsDocPersonsAllDefinition = {
        id: 'dsDocPersonsAll',
        viewName: 'aviw_Arena_DocumentsPersonsAndRolesAgg',
        //definitionProc: 'astp_Arena_DocumentsPersonsAndRolesAggDef',
        appId: props.dataObject.appId,
        uniqueTable:"atbv_Arena_DocumentsPersons",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Document_ID", type: "number" },
                {name: "Entry_ID", type: "number"},
                {name: "ForReview", type: "bit"},
                {name: "ForApproval", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "Check", type: "bit"},
                {name: "Subscribe", type: "bit"},
                {name: "EntryName", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "AllowEdit", type: "bit"},
                {name: "Redliner", type: "bit"},
                {name: "RedlineVerifier", type: "bit"},
                {name: "RedlineApprover", type: "bit"},
                {name: "RepresentsCompany", type: "string"},
                {name: "DistributionSource", type: "string"},
                {name: "OrgUnit", type: "string"},
                {name: "HasPrimaryAccess", type: "bit"},
                {name: "ForReviewEditable", type: "bit"},
                {name: "ForApprovalEditable", type: "bit"},
                {name: "ForInfoEditable", type: "bit"},
                {name: "CheckEditable", type: "bit"},
                {name: "SubscribeEditable", type: "bit"},
                {name: "AllowEditEditable", type: "bit"},
                {name: "RedlinerEditable", type: "bit"},
                {name: "RedlineVerifierEditable", type: "bit"},
                {name: "RedlineApproverEditable", type: "bit"},
                {name: "Purpose", type: "string"},
                {name: "PurposeEditable", type: "bit"},
                {name: "AllowEditEditable", type: "bit"},
                {name: "DocEntryPrimKey", type: "string"},
                {name: "Checklist", type: "string"},
                {name: "Checklist_ID", type: "number"},
                {name: "Type", type: "string"},
                {name: "Sequence", type: "number"},
                {name: "SequenceEditable", type: "bit"}
            ]
    }, dsDocPersonsDocOnlyDefinition = {
        id: 'dsDocPersonsDocOnly',
        viewName: 'aviw_Arena_DocumentsPersons',
        appId: props.dataObject.appId,
        uniqueTable:"atbl_Arena_DocumentsPersons",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Document_ID", type: "number" },
                {name: "Person_ID", type: "number"},
                {name: "ForReview", type: "bit"},
                {name: "ForApproval", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "Check", type: "bit"},
                {name: "Subscribe", type: "bit"},
                {name: "Person", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "AllowEdit", type: "bit"},
                {name: "Redliner", type: "bit"},
                {name: "RedlineVerifier", type: "bit"},
                {name: "RedlineApprover", type: "bit"},
                {name: "RepresentsCompany", type: "string"},
                {name: "OrgUnit", type: "string"},
                {name: "HasPrimaryAccess", type: "bit"},
                {name: "Purpose", type: "string"},
                {name: "Checklist", type: "string"},
                {name: "Checklist_ID", type: "number"},
                {name: "Sequence", type: "number"}
            ]
    }, dsDocRolesDocOnlyDefinition = {
        id: 'dsDocRolesDocOnly',
        viewName: 'aviw_Arena_DocumentsRoles',
        appId: props.dataObject.appId,
        uniqueTable:"atbl_Arena_DocumentsRoles",
        allowDelete: true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        disableAutoLoad: false,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "Document_ID", type: "number" },
                {name: "Role_ID", type: "number"},
                {name: "ForReview", type: "bit"},
                {name: "ForApproval", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "Check", type: "bit"},
                {name: "Role", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "Sequence", type: "number"}
                // {name: "Subscribe", type: "bit"},
                // {name: "Person", type: "string", sortOrder: 1, sortDirection: "asc"},
                // {name: "AllowEdit", type: "bit"},
                // {name: "Redliner", type: "bit"},
                // {name: "RedlineVerifier", type: "bit"},
                // {name: "RedlineApprover", type: "bit"},
                // {name: "RepresentsCompany", type: "string"},
                // {name: "OrgUnit", type: "string"},
                // {name: "HasPrimaryAccess", type: "bit"},
                // {name: "Purpose", type: "string"},
                // {name: "Checklist", type: "string"},
                // {name: "Checklist_ID", type: "number"}
            ]
    }, dsPersonsLookupDefinition = {
        id: 'dsPersonsLookup',
        viewName: 'aviw_Arena_PersonsLookup',
        appId: props.dataObject.appId,
        //maxRecords: 100,
        loadRecents: true,
        distinctRows: true,
        disableAutoLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"},
                {name: "MobileNo", type: "string"},
                {name: "Email", type: "string"},
                {name: "Represents", type: "string"}
            ]
    }

    const dsDocPersonsAll = getOrCreateDataObject(dsDocPersonsAllDefinition);
    const dsDocPersonsDocOnly = getOrCreateDataObject(dsDocPersonsDocOnlyDefinition);
    const dsDocRolesDocOnly = getOrCreateDataObject(dsDocRolesDocOnlyDefinition);
    const dsPersonsLookup = getOrCreateDataObject(dsPersonsLookupDefinition);
    
    const restrictToUsersWithAccess = ref(true);
    const personLkpWhereClause = computed(()=>{
        return "AllPersons = " + (restrictToUsersWithAccess.value ? "0" : "1")
    });

    const procDocPersonsSetStn = getOrCreateProcedure({
        id: 'procDocPersonsSetStn',
        procedureName: 'astp_Arena_DocumentsPersonsSetSetting'
    });
    const procDocPerSetPurpose = getOrCreateProcedure({
        id: 'procDocPerSetPurpose',
        procedureName: 'astp_Arena_DocumentsPersonsSetPurpose'
    });
    const procDocRolesSetStn = getOrCreateProcedure({
        id: 'procDocRolesSetStn',
        procedureName: 'astp_Arena_DocumentsRolesSetSetting'
    });
    const procInsertLkpSelection = getOrCreateProcedure({
        id: 'procInsertLkpSelection',
        procedureName: 'astp_Arena_DocumentsInsertLookupSelection'
    });
    const procDeleteDistRecord = getOrCreateProcedure({
        id: 'procDeleteDistRecord',
        procedureName: 'astp_Arena_DocumentsDeleteDistRecord'
    });
    const procCreateDistr = getOrCreateProcedure({
        id: 'procCreateDistr',
        procedureName: 'astp_Arena_DocumentCreateDistribution'
    });

    function addEntry(rows, pType) {
        let ids = rows.map(row => [row.ID]);

        procInsertLkpSelection.execute({
            Document_ID: props.dataObject.current.ID,
            Ids: ids,
            Type: pType
        }).then((res) => {
            refreshGrid();
            // refresh active grid
        });
    }

    useDataObjectEventListener(dsDocPersonsAll, 'BeforeUpdate', (options, item) => {
        options.cancelEvent = true;
    });

    useDataObjectEventListener(dsDocPersonsAll, 'FieldChanged', (pField, pNewValue, pOldValue, pRow) => {
        if (pRow.Type == 'Person'){
            if (['ForReview', 'ForInfo', 'ForApproval', 'Check', 'Subscribe', 'AllowEdit', 'Redliner', 'RedlineVerifier', 'RedlineApprover', 'Checklist_ID'].indexOf(pField) > -1){
                procDocPersonsSetStn.execute({
                    Document_ID: pRow.Document_ID,
                    Person_ID: pRow.Entry_ID,
                    Field: pField,
                    Value: !!pNewValue,
                    Value2: pNewValue
                }).then((res)=>{
                    dsDocPersonsAll.recordSource.refreshRow(pRow.index);
                });
            } else if (['Purpose', 'Sequence'].indexOf(pField) > -1){
                docPersonUpdateDebounce(pRow, pField);
            }
        } else if (['ForReview', 'ForInfo', 'ForApproval', 'Check'].indexOf(pField) > -1){
            procDocRolesSetStn.execute({
                Document_ID: pRow.Document_ID,
                Role_ID: pRow.Entry_ID,
                Field: pField,
                Value: !!pNewValue,
                Value2: pNewValue
            }).then((res)=>{
                dsDocPersonsAll.recordSource.refreshRow(pRow.index);
            });
        } else if (['Sequence'].indexOf(pField) > -1){
            docRoleUpdateDebounce(pRow, pField);
        }
    });
    
    useDataObjectEventListener(dsDocPersonsAll, 'BeforeDelete', (options, row) => {
        options.cancelEvent = true;
        if (row.DocEntryPrimKey){
            procDeleteDistRecord.execute({
                Document_ID: row.Document_ID,
                ID: row.Entry_ID,
                Type: row.Type
            }).then((res)=>{
                if (row.DistributionSource){
                    dsDocPersonsAll.recordSource.refreshRow(row.index);
                } else {
                    dsDocPersonsAll.load();
                }
            });
        } else {
            alert($t("Cannot delete entry from distribution group"), "warning");
        }
    });

    var docPersonsDebounce = {};
    function docPersonUpdateDebounce(pRow, pField){
        var vValue=event.target.value;
        if (docPersonsDebounce.hasOwnProperty(pRow.DocEntryPrimKey+pField)){
            clearTimeout(docPersonsDebounce[pRow.DocEntryPrimKey+pField]);
        }

        if (pField == "Purpose"){
            docPersonsDebounce[pRow.DocEntryPrimKey+pField] = window.setTimeout(function () {
                procDocPerSetPurpose.execute({
                    PrimKey: pRow.DocEntryPrimKey,
                    Value: vValue
                }).then((res)=>{
                    //dsDocPersonsAll.cancelChanges();
                    dsDocPersonsAll.recordSource.refreshRow(pRow.index);
                });
            }, 1000);
        } else {
            docPersonsDebounce[pRow.DocEntryPrimKey+pField] = window.setTimeout(function () {
                procDocPersonsSetStn.execute({
                    Document_ID: pRow.Document_ID,
                    Person_ID: pRow.Entry_ID,
                    Field: pField,
                    Value: !!vValue,
                    Value2: vValue
                }).then((res)=>{
                    dsDocPersonsAll.recordSource.refreshRow(pRow.index);
                });
            }, 1000);
        }
    }

    function docRoleUpdateDebounce(pRow, pField){
        var vValue=event.target.value;
        if (docPersonsDebounce.hasOwnProperty(pRow.DocEntryPrimKey+pField)){
            clearTimeout(docPersonsDebounce[pRow.DocEntryPrimKey+pField]);
        }

        docPersonsDebounce[pRow.DocEntryPrimKey+pField] = window.setTimeout(function () {
            procDocRolesSetStn.execute({
                Document_ID: pRow.Document_ID,
                Role_ID: pRow.Entry_ID,
                Field: pField,
                Value: !!vValue,
                Value2: vValue
            }).then((res)=>{
                dsDocPersonsAll.recordSource.refreshRow(pRow.index);
            });
        }, 1000);
    }

    async function distributeToPerson(pPerson_ID, pPerson){
        try {

            await confirm({
                title: $t('Inform Person?'),
                message: $t("Are you sure you would like to inform") + ' ' + pPerson + ' ' + $t("about this document profile?")
            });

            procCreateDistr.execute({
                Document_ID: props.dataObject.current.ID,
                Distribution: "ForUseSingle",
                Person_ID: pPerson_ID
            }).then((res)=>{
                console.log(res);
                alert(res.Table[0].Message, "success", { autohide: true, delay: 3000 });
            });
        } catch (_) {}
    }


    function refreshGrid(){
        if (viewMode.value == 'all'){
            dsDocPersonsAll.load();
        } else if (viewMode.value == 'docpersons'){
            dsDocPersonsDocOnly.load();
        } else if (viewMode.value == 'docroles'){
            dsDocRolesDocOnly.load();
        }
    }
    useDataObjectEventListener(dsDocPersonsDocOnly, 'BeforeDelete', (options, row)=>{
        if (props.dataObject.current.Confidential === true && userSession.personId == row.Person_ID) {
            options.cancelEvent = true;
            confirm({
                title: $t('Inform Person?'),
                message: $t("Are you sure you want to remove yourself from the distribution list? This will remove your access to this document. Confirm to proceed."),
                btnTextOk: $t('Confirm'),
                btnTextCancel: $t('Cancel'),
            }).then(async (result) => {
                if(!result['Canceled']) {
                    procDeleteDistRecord.execute({
                        Document_ID: row.Document_ID,
                        Person_ID: row.Person_ID,
                        Type: 'Person'
                    }).then(()=>{dsDocPersonsDocOnly.load()})
                }
            }).catch(()=>{});
        }
    })

    onMounted(() => {
        refreshGrid();
    });

    defineExpose({ refreshGrid });
</script>


<style>
    .customInput:focus {
        background:transparent;
        background-color: rgb(var(--bs-body-bg-rgb)) !important;
    }

</style>